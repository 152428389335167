.blog-container {
    display: grid;
    gap: 20px; /* Adds spacing between blog cards */
}

/* If there are 3 or more blogs, show 3 in a row */
.blog-container[data-count="3"],
.blog-container[data-count="4"],
.blog-container[data-count="5"],
.blog-container[data-count="6"] {
    grid-template-columns: repeat(3, 1fr);
}

/* If there are 2 blogs, show 2 in a row */
.blog-container[data-count="2"],
.blog-container[data-count="1"] {
    grid-template-columns: repeat(2, 1fr);
}

/* Ensure a single blog still follows the 2-column grid */
.blog-container[data-count="1"] .blog-card {
    grid-column: span 1; /* Keeps it within a 2-column layout */
}


.blog-card {
    background: white;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    transition: 0.3s;
    cursor: pointer;
    display: flex;
    flex-direction: column;
}

.blog-card:hover {
    transform: scale(1.03);
}

p.blog-detail-descriptionpara img {
    width: 100% !important;
}
.blog-image {
    width: 100%;
    height: 100%;
}
.main-blog-page{

    padding: 3rem 0rem;
}
.main-blog-div{
    padding: 3rem 0rem;
}
h2.blog-category {
    text-align: center;
    padding-bottom: 30px;
    font-size: 27px;
}

.blog-content {
    padding: 10px;
    flex-grow: 1;
}

.blog-title {
    font-size: 18px;
    margin: 5px 0;
}

.blog-description {
    font-size: 14px;
    color: #666;
    margin: 5px 0;
}

.read-more-blog {
    background: #007bff;
    color: white;
    border: none;
    padding: 8px 12px;
    margin-top: 10px;
    cursor: pointer;
    border-radius: 5px;
}
.blog-content.blogcontent-two {
    margin-bottom: 0px !important;
}
/* Author & Date Section */
.blog-author {
    display: flex;
    align-items: center;
    padding: 5px 0px;
    /* border-top: 1px solid #ddd; */
    margin-top: auto;
    font-size: 12px;
    margin-top: 16px;
    
    /* background: #f9f9f9; */
}
p.blog-date {
    font-size: 12px;
}
.author-image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
}

.author-details {
    margin-left: 10px;
    display: flex;
    flex-direction: column;
}

.author-name {
    font-weight: bold;
    font-size: 14px;
}

.post-date {
    font-size: 12px;
    color: gray;
}


.read-more-blog{
    margin-top: 20px;
    padding: 12px 24px;
    background-color: #1cc0d9;
    color: white;
    border: none;
    font-size: 14px;
    font-weight: 600;
    border-radius: 5px;
    cursor: pointer;
}

.read-more-blog:hover{
    background-color: #f5f5f5;
    color: #1cc0d9;
}
.blog-main-content {
    display: flex;
    justify-content: space-between;
}
.blog-imagedetail {
    width: 100%;
}
.blog-detail-container {
    padding-top: 5rem;
    width: 75%;
    margin: 0 auto;
}

img.blog-detail-image {
    width: 100%;
}

.blog-author-details {
    padding-left: 20px;
}
.blog-meta {
    display: flex
;
    align-items: center;
    width: 100%;
    justify-content: start;

}img.blog-author-image {
    border-radius: 87px;
    width: 50px;
}.blog-detail-container {
    padding-top: 5rem;
}
.blog-content {
    font-size: 16px;
}
p.blog-detail-descriptionpara {
    padding-top: 18px;
}
.blog-content {
    width: 94%;
    background: #ffffff;
    margin-top: -70px !important;
    z-index: 999999999999999999;
    position: relative;
    box-sizing: border-box;
    margin: 0 auto;
    padding: 27px 24px;
    border-radius: 15px;
    margin-bottom: 20px !important;
}
@media screen and (max-width:992px) {
   
    .blog-main-content {
        display: block;
        justify-content: space-between;
    }

        .blog-container {
            grid-template-columns: repeat(1, 1fr) !important; /* Full width on small screens */
        }

    .blog-imagedetail {
        width: 100%;
        padding: 3rem 0rem;
    }
    .blog-content {
        width: 100%;
    }
    .blog-meta{
        width: 100%;
    }
}