.chatTalk {
  .wayOfContact {
    padding-bottom: 0rem;
    display: -webkit-inline-box;
    align-items: center;
    justify-content: flex-end;
    gap: 1.6rem;
    width: 100%;
    .box {
      display: flex;
      gap: 0.4rem;
      align-items: center;
      cursor: pointer;
      .title.fs-16 {
      color: #202b40;
    font-size: 16px;
    font-weight: 500 !important;
      }
      img {
        height: 2rem;
        width: 2rem;
      }
    }
  }
  &.large {
    .box {
      gap: 1rem;
      img {
        height: 2.188rem;
      }
    }
    .title.fs-12 {
      font-size: 1.125rem !important;
    }
    .title.fs-16 {
      font-size: 1.5rem !important;
    }
  }
}
.tool-headercls .chatTalk .wayOfContact {
  display: flex
;
}