.banner {
  height: auto;
  gap: 2rem;
  align-items: center;
  justify-content: start;
  flex-direction: column;
  text-align: center;
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
}
.slick-dots {
    bottom: 16px;
}
.slick-dots li button:before {
    font-family: 'slick';
    font-size: 6px;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    content: '';
    text-align: center;
    opacity: 1;
    color: transparent;
    background: #cccccc;
    border-radius: 30px;
    border: 1px solid;
    -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
    opacity: 1;
    color: #1cc0d9;
        font-size: 23px;
            background: #1cc0d9;

}
.slick-dots li {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 0 12px;
    padding: 0;
    cursor: pointer;
}
.side-sectoion-banner{

color: #1f1f2b;
}

.design-your, .perfect-look{
    // font-family: "Varela Round", serif !important;
}

.design-your {
    font-family: "Open Sans", serif !important;
    font-weight: bold;
    font-size: 70px !important;
    text-align: center;
    width: 100%;
    display: block;
}
.perfect-look {
    font-family: "Hurricane", serif !important;
    text-transform: capitalize;
    display: block;
    text-align: center;
    font-size: 91px !important;
    margin-top: -44px;
    color: #8b8b91;
}

.bannerinner-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.shopproduct-banner{
    background-color: transparent !important;
    color: #1cbdd6 !important;
    border: 1px solid #1cbdd6;
}
.col-md-inner-one button {
    background: #1cbdd6;
    border: 0;
    letter-spacing: 3px;
    color: #f5f5f5;
    padding: 21px 28px;
    border-radius: 13px;
    font-size: 14px;
    margin-top: 23px;
    font-family: "Varela Round", serif;
    font-weight: bold;
    cursor: pointer;
    width: 100%;
    margin-right: 17px;
    box-shadow: -1px 3px 6px #aaa;
    box-sizing: border-box;
}
.button-banner {
    display: flex
;
    justify-content: space-around;
}
.button-banner a{
    width: 44%;
}
.col-md-inner-two img {
    width: 96%;
    height: 100%;
    float: right;
}
.col-md-inner-one{
width:40%;
color: white;
}
.col-md-inner-two {
    width: 60%;
    text-align: center;
}
.bannermain-div {

    // background-image: url(../../assets/images/banner_bg.webp);
    background-size:cover ;
    margin: 23px 0px;
}
.col-md-inner-one h1 {
    font-size: 48px;
    text-transform: uppercase;
    font-family: "Varela Round", serif;
    font-weight: 100;
}
.col-md-inner-one p {
    font-size: 22px;
    font-family: "Open Sans", serif;
    font-weight: 100;
    padding-top: 50px;
    text-align: center;
}
.line-banner {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
  display: none;

    width: 100%;

    height: 1px;
    background: #1f1f2b;
    border: 0;
    border-radius: 18px;
}
// .side-sectoion-banner {
//     text-align: center;
// }

@media only screen and (max-width: 1200px) {
    .bannerinner-div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        flex-direction: column-reverse;
    }

    .search-results-dropdown {

        grid-template-columns: auto auto  auto;
  
    }
    .col-md-inner-two img {
        width: 100%;
    }
    .col-md-inner-one {
        width: 100%;
        color: black;
        background: #f5f5f5;
        border-radius: 4px;
        margin: 0 auto;
        padding: 10px 12px;
        margin-bottom: 16px;
        box-sizing: border-box;
    }
    .side-sectoion-banner {
        text-align: center;
    color: #505057;

    }    
    .col-md-inner-one button {
        background: #1cbdd6;
        border: 0;
        letter-spacing: 3px;
        color: #f5f5f5;
        padding: 20px 20px;
        border-radius: 4px;
        font-size: 17px;
        margin-top: 23px;
        font-family: "Varela Round", serif;
        font-weight: bold;
        cursor: pointer;
    }
    .col-md-inner-one p {
        font-size: 18px;
        font-family: "Open Sans", serif;
        font-weight: 100;
        padding-top: 20px  !important;
    }
    hr.line-banner {
        background: gray;
    }
    .col-md-inner-two {
        width: 100%;
        text-align: center;
    }
    .col-md-inner-one h1 {
        font-size: 28px;
        text-transform: uppercase;
        font-family: "Varela Round", serif;
        font-weight: 100;
    }
    .bannermain-div {
        margin-bottom: 0px !important;
    }
    .design-your {

        font-size: 54px !important;
    }
    .button-banner{
      
        flex-direction: column;
        
    }
    .button-banner a{
        width: 100% !important;
    }
  }

  @media only screen and (max-width: 900px) {
    .search-results-dropdown {

        grid-template-columns: auto auto ;
  
    }
    .design-your {
        font-size: 45px !important;
    }.perfect-look{
        font-size: 64px !important;
        margin-top: -28px !important;
    }

  }
