header .ant-menu .ant-menu-item .ant-menu-title-content:hover {
  background: #f5f5f5;
  padding: 0px 10px;
  border-radius: 10px;
  color:#1cc0d9 !important;
}
/* .tool-headercls {
    padding: 0 !important;
} */
.tool-headercls .main-logomenu{
display: flex;
align-items: center;
}



/* Hide the checkbox but still keep it clickable */
span.ant-checkbox.ant-wave-target.css-dev-only-do-not-override-nllxry {
  display: none !important; /* Hides the actual checkbox */
}

/* Customize the clickable area, in this case, the color box */


label.ant-checkbox-wrapper.color-checkbox.css-dev-only-do-not-override-nllxry span {
padding: 0px !important;
}
.ant-collapse-content-box .ant-select.css-dev-only-do-not-override-nllxry.ant-select-single.ant-select-show-arrow {
  width: 100% !important;
}
.colors-container.filter-color {
  display: flex;
  grid-template-columns: auto auto auto;
}
 header .ant-menu .ant-menu-submenu .ant-menu-title-content:hover{

  background: #f5f5f5;
  padding: 0px 10px;
  border-radius: 10px;
  color:#1cc0d9 !important;

}
a.brand-logo.tool-logo-brand {
    border-left: 1px solid #c5c5c7;
    padding-left: 14px;
}
.anticon svg {
  display: inline-block;
  width: 17px;
  height: 13px;
}

.ant-collapse-content-box {
  padding-left: 0px !important;
    padding: 12px 16px;
    box-sizing: border-box;
}
.main-header-tool {
    background: white;
    padding: 15px 15px;
}
.main-header-tool a.brand-logo.tool-logo-brand img {
  width: 46% !important;
}
:where(.css-dev-only-do-not-override-nllxry).ant-menu-light.ant-menu-horizontal >.ant-menu-item::after, :where(.css-dev-only-do-not-override-nllxry).ant-menu-light>.ant-menu.ant-menu-horizontal >.ant-menu-item::after, :where(.css-dev-only-do-not-override-nllxry).ant-menu-light.ant-menu-horizontal >.ant-menu-submenu::after, :where(.css-dev-only-do-not-override-nllxry).ant-menu-light>.ant-menu.ant-menu-horizontal >.ant-menu-submenu::after{
  display: none;
}

.design-now-btn span.ant-menu-title-content{

  font-weight: 500 !important;
    color: white !important;
    background: #1cc0d9 !important;
    padding: 0px 32px !important;
    border-radius: 8px !important;
}
.design-now-btn span.ant-menu-title-content:hover{

  font-weight: 500;
    background: #f5f5f5 !important;
    padding: 0px 32px !important;
    border-radius: 8px !important;
}
li.ant-menu-overflow-item.ant-menu-item.ant-menu-item-only-child {
    padding: 3px !important;
}
.need-sooner-mobile{
  display: none;
  margin: 0 auto;
}

 .tagline{

font-size:14px;
 }


@media only screen and (max-width: 1200px) {

  .tool-header-cls {
    height:  calc(var(--vh, 1vh) * 10 - env(safe-area-inset-top) - env(safe-area-inset-bottom));
    align-content: center;

  }
 .tagline{

  display: none;
 }
.get-price-buttonheader {
  font-size: 12px !important;
  padding: 10px 7px !important;
  font-size: 9px;
}
.need-sooner-mobile{
  display: block;
}
.ant-row.css-dev-only-do-not-override-nllxry {
    row-gap: 3px !important;
}

  /* header .brand-logo img {
      width: 45% !important;
  } */


.slick-dots li button:before {
    font-family: "slick";
    font-size: 6px;
    line-height: 12px !important;
    position: absolute;
    top: 0px;
    left: 0;
    width: 12px !important;
    height: 12px !important;
    content: "•";
    text-align: center;
    opacity: 1;
    color: transparent;
    background: #ffffff;
    border-radius: 30px;
    border: 1px solid;
    -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
    opacity: 1;
    color: #1cc0d9;
    font-size: 12px !important;
}
.slick-dots {
    bottom: -20px !important;
}
.ant-col.tool-headercls.main-logomenu.ant-col-xs-24.ant-col-md-16.css-dev-only-do-not-override-nllxry{
  justify-content: space-between !important;
}
.main-header-tool {
    background: white;
    padding: 0px 12px;
}
}