header .ant-menu .ant-menu-item .ant-menu-title-content:hover {
  background: #f5f5f5;
  padding: 0px 10px;
  border-radius: 10px;
  color:#1cc0d9 !important;
}.ant-row.ant-row-space-between.ant-row-middle.css-dev-only-do-not-override-nllxry {
  padding: 16px 0px ! IMPORTANT;
}

 header .ant-menu .ant-menu-submenu .ant-menu-title-content:hover{

  background: #f5f5f5;
  padding: 0px 10px;
  border-radius: 10px;
  color:#1cc0d9 !important;

}
.header-hide-website {
  display: none !important;
}
:where(.css-dev-only-do-not-override-nllxry).ant-menu-light.ant-menu-horizontal >.ant-menu-item::after, :where(.css-dev-only-do-not-override-nllxry).ant-menu-light>.ant-menu.ant-menu-horizontal >.ant-menu-item::after, :where(.css-dev-only-do-not-override-nllxry).ant-menu-light.ant-menu-horizontal >.ant-menu-submenu::after, :where(.css-dev-only-do-not-override-nllxry).ant-menu-light>.ant-menu.ant-menu-horizontal >.ant-menu-submenu::after{
  display: none;
}

.design-now-btn span.ant-menu-title-content{

  font-weight: bold !important;
    color: white !important;
    background: #1cc0d9 !important;
    padding: 0px 32px !important;
    border-radius: 8px !important;
}.main-login-signup p {
  padding: 9px 3px;
  border-bottom: 1px solid #f5f5f5;
  font-size: 16px;
}
.search-results-dropdown {
  display: grid
;
  grid-template-columns: auto auto auto auto;
  padding: 20px;
  box-sizing: border-box;
}
button.close-search-btn {
  float: right;
  background: transparent;
  border: 1px solid;
  padding: 5px;
  border-radius: 5px;
}
span.search-bar-container span svg {
  width: 20px;
  height: 20px;
  box-sizing: border-box;
}

.image-preview-searchbar-main {
  text-align: center;
  /* border: 1px solid gray; */
  padding: 17px;
  box-shadow: 0px 0px 12px #f4f2f2;
}
.design-now-btn span.ant-menu-title-content:hover{

  font-weight: bold;
    background: #f5f5f5 !important;
    padding: 0px 32px !important;
    border-radius: 8px !important;
}
li.ant-menu-overflow-item.ant-menu-item.ant-menu-item-only-child {
    padding: 0 !important;
}
.need-sooner-mobile{
  display: none;
  margin: 0 auto;
}
button.icon-headers.icon-headersemail {
  display: flex !important;
}

 .tagline{

font-size:14px;
 }
 .ant-row.ant-row-space-between.ant-row-middle.css-dev-only-do-not-override-nllxry {
  padding: 0px 0px ! IMPORTANT;
}
.formobileonly{
  display: none !important;
}

@media  screen and (max-width: 1200px) {
 .tagline{

  display: none;
 }
 .ant-col.need-sooner.ant-col-md-8.css-dev-only-do-not-override-nllxry {
    display: none;
}
.need-sooner-mobile{
  display: block;
}
.ant-row.css-dev-only-do-not-override-nllxry {
    row-gap: 3px !important;
}
.slick-dots li button:before {
    font-family: "slick";
    font-size: 6px;
    line-height: 12px !important;
    position: absolute;
    top: 0px;
    left: 0;
    width: 12px !important;
    height: 12px !important;
    content: "•";
    text-align: center;
    opacity: 1;
    color: transparent;
    background: #ffffff;
    border-radius: 30px;
    border: 1px solid;
    -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
    opacity: 1;
    color: #1cc0d9;
    font-size: 12px !important;
}
.slick-dots {
    bottom: -20px !important;
}
button.icon-headers {
  font-size: 14px !important;
}
button.icon-headers img {
height: 12px !important;
}


/* header .brand-logo {


  text-align: right !important;
} */
.ant-row.ant-row-space-between.ant-row-middle.css-dev-only-do-not-override-nllxry {
  justify-content: space-between;

}
.ant-col.logo-colomn-mobile.ant-col-xs-12.ant-col-md-4.css-dev-only-do-not-override-nllxry {
  padding-left: 0px !important;
}
.ant-col.mobile-menu.ant-col-md-2.css-dev-only-do-not-override-nllxry{


padding-left: 10px !important;
    padding-right: 0px  !important;
}
button.icon-headerstwo {
  background: transparent;
}
button.icon-headerstwo img {
  height: 15px !important;
}
a.brand-logo {
  padding-left: 23x;
  margin-left: 10px;
} .ant-row.ant-row-space-between.ant-row-middle.css-dev-only-do-not-override-nllxry {
  padding: 16px 0px !important;
}
button.icon-headers.icon-headersemail {
  display: none !important;
}
.formobileonly{
  display: flex !important;
}
header .brand-logo img {
    width: 24% !important;
}

}

@media  screen and (max-width: 900px) {

  span.search-bar-container {
    margin: 0 !important;
  }
}


@media  screen and (max-width: 767px) {

  header .brand-logo img {
    width: 40% !important;
}
header.header .container .ant-row.ant-row-space-between.ant-row-middle.row-wesite-header.css-dev-only-do-not-override-nllxry {
  padding: 18px 0px !important;
}

}