.home-page {
    position: relative;
  }
  
  /* Overlay to disable clicks on the background */
  .popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
    z-index: 9999; /* Ensure it appears on top */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .popup-container h1 {
    font-size: 2em;
    font-weight: 500;
    padding-bottom: 34px;
}
  /* Popup container */
  .popup-container {
    background: #1f1f2be3;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    max-width: 100%;
    width: 46%;
    height: auto;
    overflow: auto;
}
  
  /* Popup logo */
  .popup-logo {
    max-width: 100px;
}
  
  .home-popup {
    height: 61%;
    align-items: center;
    vertical-align: middle;
    align-content: center;
    color: white;
}
  /* Popup text */
  .popup-text {
    font-size: 1.5em;
    text-transform: capitalize;
    /* margin: 20px 116px; */
    line-height: 32px;
}
  /* Close button */
  .popup-close-button {
    background-color: transparent;
    color: #1c222c;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: auto !important;

  }
  
  .popup-close-button:hover {
    background-color: transparent;
  }
  
  @media  screen and (max-width:992px) {

    .popup-text {
   
         margin: 20px !important; 
    
    }
    .home-popup {
        height: auto;

    }
    .popup-container {

      width: 90%;
      margin: 0 auto;
    
  }
  .popup-container h1 {
    font-size: 22px;

}
.popup-text {
  font-size: 15px;

}
  }